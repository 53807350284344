<template>
  <div class="container">
    <div class="row">
      <div class="col-12 py-6 text-center">
        <i class="fa-brands fa-whatsapp"></i>
        <h1>Thanks!</h1>
        <p>You will be redirected soon...</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    setTimeout(() => {
      window.location = `http://wa.me/${this.$route.query.n}`;
    }, 1000);
  },
};
</script>

<style scoped>
.fa-whatsapp {
  font-size: 4em;
  margin-bottom: 0.25em;
  color: #25d366;
}
</style>
